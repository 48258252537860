// SwiperJS
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// SwiperJS styles
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";

// Pricing page components
import SliderHeader from "../component/Header";

// Images
import bg1 from "../../../assets/images/bg2.jpg";
import bg2 from "../../../assets/images/bg3.jpg";
import bg3 from "../../../assets/images/bg3.jpg";

function Header() {
  return (
    <Swiper
      autoplay={{ delay: 5000 }}
      speed={800}
      spaceBetween={0}
      slidesPerView={1}
      navigation
      loop
      modules={[Autoplay, Navigation]} // Attach Swiper modules here
    >
      <SwiperSlide>
        <SliderHeader
          image={bg1}
          label="Pricing Plans"
          title="Information Technology"
          description="Navigate complex technology landscapes with our trusted IT consulting services. Our pricing model ensures cost-effective solutions tailored to your business objectives."
          cards={[
            {
              variant: "gradient",
              color: "info",
              icon: "shuffle",
              title: "Customized Services",
              description:
                "Experience tailored solutions that transform your business. Get a personalized quote for our expert development services that align with your unique needs.",
            },
            {
              variant: "contained",
              color: "info",
              icon: "redeem",
              title: "Saas Product Pricing",
              description:
                "Unlock the power of our cutting-edge Saas products. Choose from flexible subscription plans that fit your business requirements and propel your success.",
            },
            {
              variant: "contained",
              color: "info",
              icon: "bookmarks",
              title: "Cloud Infrastructure Services",
              description:
                "Leverage the power of the cloud with our reliable and scalable infrastructure solutions. Explore our pricing options for seamless migration, deployment, and management.",
            },
          ]}
        />
      </SwiperSlide>
      <SwiperSlide>
        <SliderHeader
          image={bg2}
          label="Optimize, Simplify and Supercharge"
          title="Software as a Service"
          description="Take control of your operations with our Saas offerings, gain real-time insights and make informed decisions for sustainable growth."
          cards={[
            {
              variant: "contained",
              color: "primary",
              icon: "shuffle_on",
              title: "Customized Services",
              description:
                "Experience tailored solutions that transform your business. Get a personalized quote for our expert development services that align with your unique needs.",
            },
            {
              variant: "gradient",
              color: "primary",
              icon: "beenhere",
              title: "Saas Product Pricing",
              description:
                "Unlock the power of our cutting-edge Saas products. Choose from flexible subscription plans that fit your business requirements and propel your success.",
            },
            {
              variant: "contained",
              color: "primary",
              icon: "ballot",
              title: "Cloud Infrastructure Services",
              description:
                "Leverage the power of the cloud with our reliable and scalable infrastructure solutions. Explore our pricing options for seamless migration, deployment, and management.",
            },
          ]}
        />
      </SwiperSlide>
      <SwiperSlide>
        <SliderHeader
          image={bg3}
          label="Competitive Cloud Services"
          title="Stay ahead of the rest"
          description="Amplify your online presence and drive results with our comprehensive solutions. Reach your target audience effectively with our competitive pricing packages."
          cards={[
            {
              variant: "contained",
              color: "error",
              icon: "tune",
              title: "Customized Services",
              description:
                "Experience tailored solutions that transform your business. Get a personalized quote for our expert development services that align with your unique needs.",
            },
            {
              variant: "contained",
              color: "error",
              icon: "settings_suggest",
              title: "Saas Product Pricing",
              description:
                "Unlock the power of our cutting-edge Saas products. Choose from flexible subscription plans that fit your business requirements and propel your success.",
            },
            {
              variant: "gradient",
              color: "error",
              icon: "compare",
              title: "Cloud Infrastructure Services",
              description:
                "Leverage the power of the cloud with our reliable and scalable infrastructure solutions. Explore our pricing options for seamless migration, deployment, and management.",
            },
          ]}
        />
      </SwiperSlide>
    </Swiper>
  );
}

export default Header;
