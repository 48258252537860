/** 
  All of the routes for the Material Kit 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui icons
import HomeIcon from "@mui/icons-material/Home";
import ServicesIcon from "@mui/icons-material/DesignServices";
import GitHubIcon from "@mui/icons-material/GitHub";

// Pages
import AboutUs from "./layouts/pages/landing-pages/about-us";
import ContactUs from "./layouts/pages/landing-pages/contact-us";
import Careers from "./layouts/pages/landing-pages/careers";
import Partners from "./layouts/pages/landing-pages/partners";

// Sections
import PageHeaders from "./layouts/sections/page-sections/page-headers";

const routes = [
  {
    name: "home",
    icon: <HomeIcon />,
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "corporate",
        collapse: [
          {
            name: "about us",
            route: "/pages/landing-pages/about-us",
            component: <AboutUs />,
          },
          {
            name: "careers",
            route: "/pages/landing-pages/careers",
            component: <Careers />,
          },
          {
            name: "partners",
            route: "/pages/landing-pages/partners",
            component: <Partners />,
          },
          {
            name: "contact us",
            route: "/pages/landing-pages/contact-us",
            component: <ContactUs />,
          },
        ],
      },
    ],
  },
  {
    name: "technology",
    icon: <ServicesIcon />,
    columns: 1, // Update to 2 columns
    rowsPerColumn: 2,
    collapse: [
      {
        name: "products",
        columns: 1, // Set columns to 1 for products
        collapse: [
          {
            name: "EnergyNow",
            href: "https://energynow.in",
            target: "_blank",
            rel: "noopener noreferrer",
          },
          {
            name: "Convoi",
            href: "https://convoiz.com",
            target: "_blank",
            rel: "noopener noreferrer",
          },
        
        ],
      },
      {
        name: "services",
        columns: 1, // Set columns to 1 for services
        collapse: [
          {
            name: "Cloud and DevOps Services",
            route: "",
            component: <PageHeaders />,
          },
          {
            name: "PTaaS",
            route: "",
            component: <PageHeaders />,
          },
          {
            name: "UI-UX Design",
            route: "",
            component: <PageHeaders />,
          },
          {
            name: "Software Development",
            route: "",
            component: <PageHeaders />,
          },
          {
            name: "Network Operations",
            route: "",
            component: <PageHeaders />,
          },
          {
            name: "Technology Consulting, Support and Maintenance",
            route: "",
            component: <PageHeaders />,
          },
          {
            name: "Enterprise Platforms",
            route: "",
            component: <PageHeaders />,
          },
        ],
      },
    ],
  },
  {
    name: "github",
    icon: <GitHubIcon />,
    href: "https://www.github.com/cynefian",
  },
];

export default routes;

